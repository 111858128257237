<template>
  <div class="main-view">
    <div class="section filter__wrap">
      <!-- 登录账号角色只有管理员才有操作权限 -->
      <el-button size="small" type="primary" icon="el-icon-plus" @click="showEdit" v-if="accountRole == 1">创建</el-button>
      <el-form ref="elFormDom" inline :model="table.params" size="small">
        <el-form-item prop="keyword">
          <el-input v-model="table.params.keyword" placeholder="成员姓名 / 账号">
            <i slot="suffix" class="el-input__icon el-icon-search" @click="onSearch"></i>
          </el-input>
        </el-form-item>
      </el-form>
    </div>
    
    <VTable
      has-pagionation
      title=''
      addText='添加'
      :field="accountRole == 1 ? field : field2"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      :showOverflowTooltip="true"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:role="{row}">
        <span :style="{'color':row.role == 1 ? '#FDA700' : ''}">{{row.role === 1 ? '管理员' : '员工'}}</span>
      </template>
      <!-- 登录账号角色只有管理员才有操作权限 -->
      <template v-slot:action="{row}" v-if="accountRole == 1">
        <el-button type="text" @click='showPass(row)'>修改密码</el-button>
        <span class="action-btn-line">|</span>
        <el-button type="text" @click='showEdit(row)'>编辑</el-button>
        <!-- 删除操作条件： 当前的登录账号没有删除按钮（只能删别人，不能删自己） -->
        <template v-if="userName != row.account">
          <span class="action-btn-line">|</span>
          <el-button type="text" class="colorDel" @click='remove(row)'>删除</el-button>
        </template>
      </template>
    </VTable>

    <edit ref="edit" :powerArr="curPowerArr" @refresh="getTable"></edit>
    <password ref="pass"></password>
  </div>
</template>

<script>
import VTable from '@/components/VTable';
import Edit from './components/Edit.vue'
import Password from './components/Password.vue'
import { mixinTable } from '@/mixins/table.js'
import { getStorage } from '@/storage'
export default {
  name: 'AccountList',
  components: {
    VTable,
    Edit,
    Password
  },
  mixins: [mixinTable],
  data() {
    return {
      field: [
        { name: "name", label: "成员姓名", hidden: false },
        { name: "account", label: "登录账号", hidden: false },
        { name: "role", label: "所属角色", hidden: false},
        { name: "create_time", label: "创建时间", hidden: false},
        { name: "last_login_time", label: "最近登录时间", hidden: false},
        { name: "action", label: "操作", width: "200", hidden: false }
      ],
      field2: [
        { name: "name", label: "成员姓名", hidden: false },
        { name: "account", label: "登录账号", hidden: false },
        { name: "role", label: "所属角色", hidden: false},
        { name: "create_time", label: "创建时间", hidden: false},
        { name: "last_login_time", label: "最近登录时间", hidden: false},
      ],
      table: {
        loading: false,
        params: {
          keyword: '',
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
      curPowerArr: [],
      accountRole: getStorage('accountRole'),
      userName: getStorage('userName')
    }
  },
  created() {
    this.getTable();
  },
  methods: {
    // 查询列表
    getTable() {
      this.$http.get('/company/account/list', {params: this.table.params}).then(res => {
        if(res.code === 1) {
          this.table.data = res.data.list
          this.table.total = res.data.total;
          // 重置查询条件
          this.$refs.elFormDom.resetFields()
          this.table.params.page = 1;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    showEdit(row) {
      let dom = this.$refs.edit
      dom.toggle(true)
      if (!!row.id) {
        dom.getDetail(row)
      }
      dom = null
    },
    showPass(row) {
      let dom = this.$refs.pass
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    // 删除
    remove(row) {
      this.$confirm("是否删除此条记录？", "删除", {
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            this.$http.post('/company/account/del', {id: row.id}).then(res => { 
              if(res.code === 1) {
                this.$message.success('操作成功！');
                this.getTable();
              } else {
                this.$message.error(res.msg)
              }
            })
            done()
          } else {
            done()
          }
        }
      })
    }
  }
}
</script>